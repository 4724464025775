import PropTypes from 'prop-types';
import Price from './Price';
import Occupants from './Occupants';

const Origin = {
  code: PropTypes.string,
  name: PropTypes.string,
};

const PromotedOrigin = {
  origin: PropTypes.shape(Origin),
  price: {
    total: PropTypes.shape(Price),
    perTraveller: PropTypes.shape(Price),
  },
};

const Destination = {
  name: PropTypes.string,
  title: PropTypes.string,
};

const Tag = {
  code: PropTypes.string,
  name: PropTypes.string,
};

const PropertyContent = {
  destination: PropTypes.shape(Destination),
  id: PropTypes.string,
  name: PropTypes.string,
  tagline: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape(Tag)),
};

const PackageOffer = {
  total: PropTypes.shape(Price),
  perTraveller: PropTypes.shape(Price),
  valuedAtTotal: PropTypes.shape(Price),
};

const Inclusion = {
  code: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
};

const Travellers = {
  ...PropTypes.shape(Occupants),
  total: PropTypes.number,
};

const LeadInOffer = {
  title: PropTypes.string,
  description: PropTypes.string,
  promotionalSash: PropTypes.string,
  packageOffers: PropTypes.objectOf(PropTypes.shape(PackageOffer)),
  hasValuedAtPrice: PropTypes.bool,
  valuedAtTotal: PropTypes.shape(Price),
  inclusions: PropTypes.arrayOf(PropTypes.shape(Inclusion)),
  minNumberOfNights: PropTypes.number,
  displayTravellerTypes: PropTypes.bool,
  travellers: PropTypes.shape(Travellers),
};

const Room = {
  leadInOffers: PropTypes.arrayOf(PropTypes.shape(LeadInOffer)),
  legal: PropTypes.string,
  room: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

const Date = {
  start: PropTypes.string,
  end: PropTypes.string,
};

const ProcuredOffer = {
  brands: PropTypes.arrayOf(PropTypes.string),
  extended: PropTypes.bool,
  highlights: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    inclusions: PropTypes.arrayOf(PropTypes.shape(Inclusion)),
  }),
  name: PropTypes.string,
  rooms: PropTypes.arrayOf(PropTypes.shape(Room)),
  saleDates: PropTypes.shape(Date),
  terms: PropTypes.string,
  travelDates: PropTypes.shape(Date),
};

const FeaturedProperty = {
  cheapestOrigin: PropTypes.shape(PromotedOrigin),
  nights: PropTypes.number,
  pillText: PropTypes.string,
  propertyOrigins: PropTypes.arrayOf(PropTypes.shape(Origin)),
  tagline: PropTypes.string,
  travellers: PropTypes.shape(Travellers),
};

const CrossSellBanner = {
  id: PropTypes.string,
  type: PropTypes.string,
};

const CampaignFeaturedProperty = {
  id: PropTypes.string,
  type: PropTypes.string,
  featuredProperty: PropTypes.shape(FeaturedProperty),
  propertyContent: PropTypes.shape(PropertyContent),
};

const CampaignProcuredOffer = {
  id: PropTypes.string,
  type: PropTypes.string,
  propertyContent: PropTypes.shape(PropertyContent),
  procuredOffer: PropTypes.shape(ProcuredOffer),
};

const Tile = PropTypes.oneOfType([
  PropTypes.shape(CampaignFeaturedProperty),
  PropTypes.shape(CampaignProcuredOffer),
  PropTypes.shape(CrossSellBanner),
]);

export { ProcuredOffer, Inclusion, Tile as default };
