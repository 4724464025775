import dataLayer from '@/v2/utils/dataLayer';

export const checkAvailabilityDataLayerEvent = ({
  pageName,
  packageOption,
}: {
  pageName: string;
  packageOption: string;
}) =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `Check availability ${packageOption}`,
    label: `${pageName} popup`,
  });
