import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@qga/roo-ui/components';

const PropertyTags = ({ tags, size }) =>
  tags.slice(0, size ? size : tags.length).map((tag, i) =>
    i === 0 ? (
      <Text key={tag.name} color="greys.steel" fontSize="sm">
        {tag.name}
      </Text>
    ) : (
      <Text key={tag.name} color="greys.steel" fontSize="sm">
        {' '}
        &#11825;
        {` ${tag.name}`}
      </Text>
    ),
  );

PropertyTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  size: PropTypes.number,
};

export default PropertyTags;
