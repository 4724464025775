import pluralize from 'pluralize';
import { differenceInDays } from '@experiences-ui/shared/utils/date';
import format, { MEDIUM_DATE_FORMAT } from '@/lib/date/format';
import { startOfDay } from '@/lib/date';

const getProcuredOfferSash = ({ saleDateRange: { end }, extended }) => {
  if (!end) {
    return {
      text: 'EXCLUSIVE PACKAGE OFFER',
      type: 'default',
      icon: 'star',
    };
  }
  const daysUntilSaleEnd = differenceInDays(
    new Date(end.utc),
    startOfDay(new Date()),
  );

  const type = daysUntilSaleEnd > 3 ? 'default' : 'warning';

  const saleEnd = format(MEDIUM_DATE_FORMAT)(end.local);
  const endText = extended ? 'EXTENDED TO' : 'ENDS';
  const getText = () => {
    if (daysUntilSaleEnd < 1) return 'ENDS TONIGHT';
    if (daysUntilSaleEnd <= 3)
      return `ENDS IN ${pluralize('DAY', daysUntilSaleEnd, true)}`;

    return `${endText} ${saleEnd}`;
  };

  return {
    text: getText(),
    type,
    icon: 'accessTime',
  };
};

export default getProcuredOfferSash;
