const sortImages = (images, mainImage) => {
  const sortedImages = [];

  if (mainImage) {
    sortedImages.push(mainImage);
  }
  if (images) {
    sortedImages.push(
      ...images.filter((img) => {
        const originalMatch = img.original !== mainImage.original;
        const largeMatch = img.large !== mainImage.large;
        return originalMatch || largeMatch;
      }),
    );
  }

  return sortedImages;
};

export default sortImages;
