import React from 'react';
import packageTranslations from '@/v2/locales/en/package.json';
import { PackageOption } from '@/libs/v2/utils/getPackageOptions/getPackageOptions';
import { Flex } from '@qga/roo-ui/components';
import PropertyTitle from '../PropertyTitle';
import Modal from '../Modal';
import { Tile } from '@/v2/types/ui';
import PackageOptionCard from './components/PackageOptionCard';

interface AllPackageOptionsModal {
  onClose: () => void;
  tile: Tile;
  packageOptions: PackageOption[];
}

const AllPackageOptionsModal = ({
  onClose,
  tile,
  packageOptions,
}: AllPackageOptionsModal) => {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>
        {packageTranslations.packageOptions.modal.title}
      </Modal.Header>
      <Modal.Body>
        <Flex
          bg={'background.card'}
          pb={[1, null, 3]}
          pt={['20px', null, 4]}
          px={[5, null, 5]}
        >
          <PropertyTitle
            propertyName={tile?.propertyContent.name}
            rating={tile?.rating}
            ratingType={tile?.ratingType}
            tags={tile?.propertyContent.tags || []}
          />
        </Flex>
        <Flex
          flexDirection="column"
          bg={'background.page'}
          pt="1"
          pb={[4, null, 6]}
          px={[5, null, 5]}
          gap={6}
        >
          {packageOptions.map((packageOption, index) => (
            <PackageOptionCard
              key={packageOption.id}
              id={packageOption.id}
              packageOption={packageOption}
              tile={tile}
              index={index}
            />
          ))}
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default AllPackageOptionsModal;
