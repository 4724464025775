import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { Box } from '@qga/roo-ui/components';
import { useAppSettings } from '@/components/AppSettingsProvider';
import { useApp } from '@/v2/contexts/app';
import Link from '@/components/Link';
import PointsPerDollar from '@/components/PointsPerDollar';
import withProps from '@/components/withProps';

const ImportantInformation = () => {
  const appSettings = useAppSettings();
  const app = useApp();
  const importantInformation =
    appSettings?.importantInformation || app?.importantInformation;

  return (
    <BlockContent
      blocks={importantInformation}
      serializers={{
        types: {
          block: withProps(({ node }) => ({
            ...(node.style === 'title' && {
              fontSize: 'md',
              fontWeight: 'bold',
            }),
            ...(node.style === 'normal' && {
              fontSize: 'sm',
            }),
            color: 'greys.steel',
            my: '3',
          }))(Box),
        },
        marks: {
          pointsPerDollar: PointsPerDollar,
          link: withProps(({ mark }) => ({
            href: mark.href,
            external: mark.external,
            underline: true,
          }))(Link.Text),
        },
      }}
    />
  );
};

export default ImportantInformation;
