import React from 'react';
import { Box, Flex, Heading } from '@qga/roo-ui/components';
import PropertyTags from '@/components/PropertyTags';
import StarRating from '@/libs/v2/components/StarRating';
import { compose } from 'ramda';

const dataTestId = 'PROPERTY_TITLE';

interface PropertyTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  propertyName: string;
  rating: number;
  ratingType: 'AAA' | 'SELF_RATED';
  tags?: Array<{ name: string }>;
}

export const PropertyTitle = ({
  propertyName,
  rating,
  ratingType,
  tags,
  ...props
}: PropertyTitleProps) => (
  <Flex flexDirection="column" data-testid={dataTestId} {...props}>
    <Heading.h3 textStyle="h5" mb={[1, null, 0]} lineHeight="30px">
      {propertyName}
    </Heading.h3>
    <Flex
      alignItems={[null, null, 'center']}
      flexDirection={['column', null, 'row']}
      mb={[6, null, 2]}
    >
      <StarRating rating={rating} ratingType={ratingType} size={14} />
      {tags && (
        <Box ml={[null, null, 4]} py={[0, null, 0]}>
          <PropertyTags tags={tags} size={3} />
        </Box>
      )}
    </Flex>
  </Flex>
);

export default compose(React.memo)(PropertyTitle);
