import React from 'react';
import { useAppSettings } from '@/components/AppSettingsProvider';

const PointsPerDollar = React.memo(() => {
  const { pointsPerDollar } = useAppSettings();

  return pointsPerDollar;
});

export default PointsPerDollar;
